<template>
  <svg
    width="85"
    height="32"
    viewBox="0 0 85 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="85" height="32" rx="4" fill="#4CA7D8" />
    <g clip-path="url(#clip0_25961_17340)">
      <path
        d="M29.3327 12L22.9993 18.3333L19.666 15L14.666 20"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.334 12H29.334V16"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <rect x="43" y="2" width="40" height="28" rx="3" fill="white" />
    <path
      d="M60.334 12H69.0007"
      stroke="#4CA7D8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.334 16H69.0007"
      stroke="#4CA7D8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.334 20H69.0007"
      stroke="#4CA7D8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M57 12H57.0067"
      stroke="#4CA7D8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M57 16H57.0067"
      stroke="#4CA7D8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M57 20H57.0067"
      stroke="#4CA7D8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <clipPath id="clip0_25961_17340">
        <rect width="16" height="16" fill="white" transform="translate(14 8)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SupervisorSwitch',
}
</script>
