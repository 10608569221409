import ArrowDownIcon from '@/components/icons/ArrowDown.vue'
import DriverCheck from '@/components/icons/DriverCheck'
import DriverPlus from '@/components/icons/DriverPlus'
import IconAccept from '@/components/icons/Accept'
import IconAttention from '@/components/icons/Attention'
import IconAttentionCircle from '@/components/icons/AttentionCircle'
import IconBell from '@/components/icons/Bell'
import IconClose from '@/components/icons/Close'
import IconColumn from '@/components/icons/Column'
import IconCompanyEdit from '@/components/icons/CompanyEdit'
import IconCompanyStatusOk from '@/components/icons/CompanyStatusOk'
import IconControl from '@/components/icons/Control'
import IconDriverSlots from '@/components/icons/DriverSlots'
import IconDriverTakeSlot from '@/components/icons/DriverTakeSlot'
import IconEntry from '@/components/icons/Entry'
import IconExtraMenu from '@/components/icons/ExtraMenu'
import IconGoToTerminal from '@/components/icons/GoToTerminal'
import IconHelp from '@/components/icons/Help'
import IconInfo from '@/components/icons/Info'
import IconLeftAngle from '@/components/icons/LeftAngle.vue'
import IconLine from '@/components/icons/Line'
import IconList from '@/components/icons/List.vue'
import IconMenu from '@/components/icons/Menu'
import IconMenuLine from '@/components/icons/MenuLine'
import IconPen from '@/components/icons/Pen'
import IconProfile from '@/components/icons/Profile'
import IconQuotas from '@/components/icons/Quotas'
import IconRefresh from '@/components/icons/Refresh'
import IconRotate from '@/components/icons/Rotate'
import IconSearch from '@/components/icons/Search'
import IconSearchRight from '@/components/icons/SearchRight'
import IconSearchWhite from '@/components/icons/SearchWhite'
import IconSettings from '@/components/icons/IconSettings'
import IconSupervisorSwitchGant from '@/components/icons/SupervisorSwitchGant.vue'
import IconSupervisorSwitchTable from '@/components/icons/SupervisorSwitchTable.vue'
import IconTableDelete from '@/components/icons/TableDelete'
import IconTableEdit from '@/components/icons/TableEdit'
import IconTableMenu from '@/components/icons/TableMenu'
import IconTrash from '@/components/icons/Trash'
import IconTruckIconQuota from '@/components/icons/TruckIconQuota'
import IconUserRegistration from '@/components/icons/UserRegistration'
import LogoPortTranzit from '@/components/icons/LogoPortTranzit'
import SaveIcon from '@/components/icons/SaveIcon'
import TruckIcon from '@/components/icons/TruckIcon'
import WrenchIcon from '@/components/icons/WrenchIcon'

export default {
  IconAccept,
  IconSearchWhite,
  IconAttentionCircle,
  DriverPlus,
  DriverCheck,
  LogoPortTranzit,
  IconEntry,
  WrenchIcon,
  SaveIcon,
  TruckIcon,
  IconColumn,
  IconCompanyEdit,
  IconCompanyStatusOk,
  IconLeftAngle,
  IconLine,
  IconList,
  IconClose,
  IconMenu,
  IconMenuLine,
  IconQuotas,
  IconProfile,
  IconControl,
  IconHelp,
  IconExtraMenu,
  IconSearchRight,
  IconTableDelete,
  IconTableEdit,
  IconTableMenu,
  IconAttention,
  IconDriverSlots,
  IconDriverTakeSlot,
  IconGoToTerminal,
  IconUserRegistration,
  IconRotate,
  IconTruckIconQuota,
  IconPen,
  IconTrash,
  IconSearch,
  IconSettings,
  IconInfo,
  IconRefresh,
  ArrowDownIcon,
  IconSupervisorSwitchGant,
  IconSupervisorSwitchTable,
  IconBell,
}
