var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "21",
        height: "21",
        viewBox: "0 0 21 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9.625 16.625C13.491 16.625 16.625 13.491 16.625 9.625C16.625 5.75901 13.491 2.625 9.625 2.625C5.75901 2.625 2.625 5.75901 2.625 9.625C2.625 13.491 5.75901 16.625 9.625 16.625Z",
          stroke: "#606266",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.3751 18.3751L14.5688 14.5688",
          stroke: "#606266",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }