var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.name, {
    tag: "component",
    staticClass: "iq-icon",
    style: _vm.iconSize,
    nativeOn: {
      click: function ($event) {
        return _vm.handleClick.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }