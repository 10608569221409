var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "85",
        height: "32",
        viewBox: "0 0 85 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: { width: "85", height: "32", rx: "4", fill: "#4CA7D8" },
      }),
      _c("g", { attrs: { "clip-path": "url(#clip0_25961_17340)" } }, [
        _c("path", {
          attrs: {
            d: "M29.3327 12L22.9993 18.3333L19.666 15L14.666 20",
            stroke: "white",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M25.334 12H29.334V16",
            stroke: "white",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
      ]),
      _c("rect", {
        attrs: {
          x: "43",
          y: "2",
          width: "40",
          height: "28",
          rx: "3",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M60.334 12H69.0007",
          stroke: "#4CA7D8",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M60.334 16H69.0007",
          stroke: "#4CA7D8",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M60.334 20H69.0007",
          stroke: "#4CA7D8",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M57 12H57.0067",
          stroke: "#4CA7D8",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M57 16H57.0067",
          stroke: "#4CA7D8",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M57 20H57.0067",
          stroke: "#4CA7D8",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_25961_17340" } }, [
          _c("rect", {
            attrs: {
              width: "16",
              height: "16",
              fill: "white",
              transform: "translate(14 8)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }