<template>
  <component
    :is="name"
    class="iq-icon"
    :style="iconSize"
    @click.native="handleClick"
  />
</template>

<script>
import icons from '@/components/icons'

export default {
  name: 'Icon',
  components: {
    ...icons,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: '#000000',
    },
    width: {
      type: String,
      default: '18px',
    },
    height: {
      type: String,
      default: '18px',
    },
  },
  computed: {
    iconSize() {
      return {
        width: this.width,
        height: this.height,
      }
    },
  },
  methods: {
    handleClick(event) {
      this.$emit('onClick', event)
    },
  },
}
</script>

<style lang="sass">
.iq-icon
  & + &
    margin-left: 15px
</style>
